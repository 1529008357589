import { prop } from 'rambda'
import dotenv from 'dotenv'
import { Config, Country } from './types'

const configuration: any = { path: '../../.env' }
dotenv.config(configuration)

const {
  REACT_APP_PROJECT_COMMIT_DATE,
  REACT_APP_PROJECT_COMMIT_SHORT_SHA,
  REACT_APP_CONFIG_FILE,
  REACT_APP_WEB_URL
  // REACT_APP_USE_DOKOBIT,
  // REACT_APP_USE_EID_LOGIN
} = process.env

// Dynamically read config file
const configFile = REACT_APP_CONFIG_FILE ?? 'defaultConfig'
const configModule = require(`./${configFile}`)
const config: Config = configModule.default

const envVariables = {
  webUrl: REACT_APP_WEB_URL || 'https://playground.ecmr4.sis.lt',
  projectCommitDate: REACT_APP_PROJECT_COMMIT_DATE ?? '',
  projectCommitShortSHA: REACT_APP_PROJECT_COMMIT_SHORT_SHA ?? '',
  storageType: 1,
  // Config values
  countries: config.countries,
  countriesKeys: config?.countries.map(prop('key')),
  middlewareUrl: config.middlewareUrl,
  fileStorageUrl: config.fileStorageUrl,
  useDokobit: true, // true ? REACT_APP_USE_DOKOBIT : false
  useIsenseLogin: false, // REACT_APP_USE_EID_LOGIN
  useEIdEasy: true,
  useVP: false,
  eIdEasyClientId: config.eIdEasyClientId,
  loginWithVPWebSocketURL: 'wss://api-v2.did.sis.lt/v1/ws'
}

export default envVariables

export const getCountryByKey = (countryKey: string): Country | undefined => {
  return envVariables.countries.find(({ key }) => countryKey === key)
}

export const getCountryAPIURLByKey = (countryKey: string): string | undefined => {
  return (envVariables.countries.find(({ key }) => countryKey === key) ?? {})?.apiUrl
}
