import axios from "axios";
import config from '../../config'

export const instance = axios.create({
    baseURL: `${config.middlewareUrl}/api/dokobit/v1`,
});

export const createInstance = (url) => {
    const temp = axios.create({
        baseURL: url,
    });
    temp.defaults.headers.common["Authorization"] = instance.defaults.headers.common["Authorization"]
    return temp
}

export const setAccessToken = (token) => {
    if (token) {
        instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
        delete instance.defaults.headers.common["Authorization"];
    }
}