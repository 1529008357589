export const COUNTRY_CODES = [
    "lt",
    "ee",
    "lv",
    "pl",
    "fi",
    "ge",
    "ua",
    "ru",
    "se",
    "by",
    "ru",
    "be",
    "nl",
    "fr",
    "lu",
    "sp",
    "pt",
    "no",
    "dk",
];

export const ACTION_TYPES = {
    SET_LOGIN_STATE: "SET_LOGIN_STATE"
}
