import {ACTION_TYPES} from "../../consts";

const initialState = {};

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_LOGIN_STATE:
      return {
        ...state,
        ...action.payload,
        // isLoggedIn: true,
      };
    default:
      return state;
  }
};
