import React from "react";
import { getFile, getFileAccess } from "../../services/cloudStorage";
import {
    EditOutlined,
    EyeOutlined,
    UserOutlined,
} from '@ant-design/icons';
import config from "../../config";
import { StorageType } from "../../services/document";
import { getCurrentUserInfo } from "../../services/indexRecord";
import { scopes } from "./const";
import { mergeDeepWith, path, concat } from "ramda";

export const parseFileIdFromUri = (uri: string) => uri
    .split('/')
    .slice(-1)[0]

export const getFileAccessByUri = (uri: string): any => {
    if (config.storageType === StorageType.Cloud) {
        return getFileAccess(parseFileIdFromUri(uri))
    }
}

export const replaceTextVariables = (text, variables) => {
    let mailTextWithVariables = text

    const matches = text
        .match(new RegExp('(?<={{).+?(?=}})', 'ig'))


    if (!matches) return mailTextWithVariables

    matches
        .forEach((key) => {
            mailTextWithVariables = mailTextWithVariables
                .replace(new RegExp(`{{${key}}}`, 'gi'), path(key.split('.'))(variables))
        })
    return mailTextWithVariables
}

const flattenObject = (ob) => {
    var toReturn = {};

    for (var i in ob) {
        if (!ob.hasOwnProperty(i)) continue;

        if ((typeof ob[i]) == 'object' && ob[i] !== null) {
            var flatObject = flattenObject(ob[i]);
            for (var x in flatObject) {
                if (!flatObject.hasOwnProperty(x)) continue;
                const prefix = i.replace(".", "_")
                toReturn[`${prefix}_${x}`] = flatObject[x];
            }
        } else {
            toReturn[i] = ob[i];
        }
    }
    return toReturn;
}

const mergeAccess = (accessList) => {
    return accessList.reduce((finalAccess, currentAccess) => {
        return mergeDeepWith(concat, finalAccess, currentAccess)
    }, {
        actions: [],
        accessActions: [],
        allUserAccess: {},
    })
}

export const scopesPersonalized = () => Object.values(scopes).map(scope => {
    return replaceTextVariables(scope, flattenObject(getCurrentUserInfo()))
}).map(item => item.toLowerCase())

export const getAllScopesManagableAccess = (items, type) => {
    const accessCurrentScopes = scopesPersonalized()
        .map(scope => getManagableAccess(items, type, scope))
    const access = mergeAccess(accessCurrentScopes)
    return access
}

export const getManagableAccess = (items, type, scope?: string, skipAllUserAccess?: boolean) => {

    if (!items) {
        return { accessActions: [], actions: [], allUserAccess: {} }
    }

    const access = items
        .filter(({ policies }) => {
            return policies
                .filter(({ resources }) => !!resources
                    .find((resource) => (resource.indexOf(type) > -1 ||
                        resource.indexOf('<.*>') > -1))).length > 0
        })
        .map(({ policies, userID }) => {
            return {
                userID,
                actions: policies
                    .filter(({ resources }) => !!resources
                        .find((resource) => (resource.indexOf(type) > -1 ||
                            resource.indexOf('<.*>') > -1)))
                    .flatMap(({ actions }) => actions),
                accessActions: policies
                    .filter(({ resources }) => !!resources
                        .find((resource) => (resource.indexOf('access') > -1 ||
                            resource.indexOf('<.*>') > -1)))
                    .flatMap(({ actions }) => actions),
                allUserAccess: skipAllUserAccess ? {} : {
                    metadata: getManagableAccess(items, "metadata", scope, true),
                    content: getManagableAccess(items, "content", scope, true),
                    access: getManagableAccess(items, "access", scope, true)
                }
            }
        })
        .filter(({ userID }) => (scope === userID || scope == null))

    if (scope) {
        return access.length ? access[0] : { actions: [], accessActions: [], allUserAccess: {} }
    }

    return access
}

export const getFileMetadataByUri = (uri: string): any => {
    if (config.storageType === StorageType.Cloud) {
        return getFile(parseFileIdFromUri(uri))
    }
}

export const getAccessOptions = (ownerId) => [
    {
        key: 'admin',
        label: 'Admin',
        icon: <UserOutlined />,
        optionAccess: 'owner',
        access: [
            {
                type: "allow",
                source: "metadata",
                actions: ['get', 'update']
            },
            {
                type: "allow",
                source: "content",
                actions: ['get', 'update']
            },
            {
                type: "allow",
                source: "access",
                actions: ['get', 'create', 'update', 'delete'],
                // scope: ['<.*>']
            },
            {
                type: "deny",
                source: "access",
                actions: ['create', 'update', 'delete'],
                scope: [ownerId]
            },
        ]
    },
    {
        key: 'edit',
        label: 'Edit',
        icon: <EditOutlined />,
        optionAccess: 'admin',
        access: [
            {
                type: "allow",
                source: "metadata",
                actions: ['get', 'update']
            },
            {
                type: "allow",
                source: "content",
                actions: ['get', 'update']
            },
            {
                type: "allow",
                source: "access",
                actions: ['get']
            },
        ]
    },
    {
        key: 'view',
        label: 'View',
        icon: <EyeOutlined />,
        optionAccess: 'admin',
        access: [
            {
                type: "allow",
                source: "metadata",
                actions: ['get']
            },
            {
                type: "allow",
                source: "content",
                actions: ['get']
            },
            {
                type: "allow",
                source: "access",
                actions: ['get'],
            }
        ]
    }
]