/* eslint-disable */

import { Base64 } from 'js-base64';

export const parseJwt = (token) => {
  try {
    return JSON.parse(Base64.decode(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};


