import { Dispatch } from "react";
import { getFile, getFileAccess } from "../../../services/cloudStorage";
import { getIndex, updateIndex, addIndex, findIndex } from "../../../services/indexRecord/indexRecord";
import { ActionTypes, DocumentAction } from "./types";


const getMethodByName: any = (name) => {
    switch (name) {
        case 'GET_DOCUMENT_INDEX':
            return getIndex
        case 'GET_FILE_ACCESS':
            return getFileAccess
        case 'GET_FILE_METADATA':
            return getFile
        case 'UPDATE_DOCUMENT_INDEX':
            return updateIndex
        case 'ADD_DOCUMENT_INDEX':
            return addIndex
        case 'FIND_DOCUMENT_INDEX':
            return findIndex
        case 'CLEAR_DOCUMENT_INDEX':
            return () => { }
    }
}

export const dispatchBase = (methodName, ...params) => async (dispatch: Dispatch<DocumentAction>) => {
    dispatch({ type: ActionTypes[`REQUEST_${methodName}`] });
    try {
        const response: any = await getMethodByName(methodName)(...params)
        return dispatch({ type: ActionTypes[`SUCCESS_${methodName}`], data: response && response.data, params: [...params] });
    } catch (err) {
        if (err.response && err.response.data) {
            dispatch({ type: ActionTypes[`ERROR_${methodName}`], error: err.response.data.message });
            const error: any = new Error(err.response.data.message)
            error.response = err.response
            throw error
        } else if (err.response) {
            throw new Error(err.response.statusText)
        } else {
            dispatch({ type: ActionTypes[`ERROR_${methodName}`], error: err.message });
            throw new Error(err.message)
        }
    }
}


export const addDocumentIndexAction = (dispatch) => (country, index) => {
    return dispatchBase('ADD_DOCUMENT_INDEX', country, index)(dispatch)
}

export const getDocumentIndexAction = (dispatch) => (country, id, history) => {
    return dispatchBase('GET_DOCUMENT_INDEX', country, id, history)(dispatch)
}

export const getFileAccessAction = (dispatch) => (fileId) => {
    return dispatchBase('GET_FILE_ACCESS', fileId)(dispatch)
}

export const getFileMetadataAction = (dispatch) => (fileId, includePolicies = true) => {
    return dispatchBase('GET_FILE_METADATA', fileId, includePolicies)(dispatch)
}

export const updateDocumentIndexAction = (dispatch) => (country, index) => {
    return dispatchBase('UPDATE_DOCUMENT_INDEX', country, index)(dispatch)
}

export const findDocumentIndexAction = (dispatch) => (country, truck_reg_number, transport_country_code) => {
    return dispatchBase('FIND_DOCUMENT_INDEX', country, truck_reg_number, transport_country_code)(dispatch)
}

export const clearDocumentIndexAction = (dispatch) => () => {
    return dispatchBase('CLEAR_DOCUMENT_INDEX')(dispatch)
}