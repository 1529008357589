import dotenv from 'dotenv'

const configuration: any = { path: '../../.env' }
dotenv.config(configuration)

const {
  REACT_APP_STORAGE_URL,
  REACT_APP_SSO_URL,
  REACT_APP_API_BASE_URL,
  REACT_APP_MIDDLEWARE_URL
} = process.env

const DEFAULT_SSO = 'https://sso.playground.ecmr4.sis.lt';
const DEFAULT_STORAGE_URL = 'https://api.cloud.corposign.net/v1/storage';
const DEFAULT_MIDDLEWARE_URL = 'https://middleware.playground.ecmr4.sis.lt';
const DEFAULT_API_BASE_URL = 'playground.ecmr4.sis.lt/v1';

const config = {
  middlewareUrl: REACT_APP_MIDDLEWARE_URL || DEFAULT_MIDDLEWARE_URL, // "http://localhost:8080"
  fileStorageUrl: REACT_APP_STORAGE_URL || DEFAULT_STORAGE_URL,
  eIdEasyClientId: 'Jq3dkKsezuO7wXb62h842ZKUQWzSodnI', // "kWciHBseCA7nsBEg1vr7Teyr2NGsT4C8",
  countries: [
    {
      key: 'lt',
      name: 'Lithuania',
      apiUrl: `https://api.lt.${REACT_APP_API_BASE_URL || DEFAULT_API_BASE_URL}`,
      logo64: '/img/flags/lt-flag-64.png',
      sso: {
        url: REACT_APP_SSO_URL || DEFAULT_SSO,
        realm: 'lt'
      }
    },
    {
      key: 'pl',
      name: 'Poland',
      apiUrl: `https://api.pl.${REACT_APP_API_BASE_URL || DEFAULT_API_BASE_URL}`,
      logo64: '/img/flags/pl-flag-64.png',
      sso: {
        url: REACT_APP_SSO_URL || DEFAULT_SSO,
        realm: 'pl'
      }
    },
    {
      key: 'ee',
      name: 'Estonia',
      apiUrl: `https://api.ee.${REACT_APP_API_BASE_URL || DEFAULT_API_BASE_URL}`,
      logo64: '/img/flags/ee-flag-64.png',
      sso: {
        url: REACT_APP_SSO_URL || DEFAULT_SSO,
        realm: 'ee'
      }
    },
    {
      key: 'lv',
      name: 'Latvia',
      apiUrl: `https://api.lv.${REACT_APP_API_BASE_URL || DEFAULT_API_BASE_URL}`,
      logo64: '/img/flags/lv-flag-64.png',
      sso: {
        url: REACT_APP_SSO_URL || DEFAULT_SSO,
        realm: 'lv'
      }
    },
    {
      key: 'ua',
      name: 'Ukraine',
      apiUrl: `https://api.ua.${REACT_APP_API_BASE_URL || DEFAULT_API_BASE_URL}`,
      logo64: '/img/flags/ua-flag-64.png',
      sso: {
        url: REACT_APP_SSO_URL || DEFAULT_SSO,
        realm: 'ua'
      }
    },
    {
      key: 'ru',
      name: 'Russia',
      apiUrl: `https://api.ru.${REACT_APP_API_BASE_URL || DEFAULT_API_BASE_URL}`,
      logo64: '/img/flags/ru-flag-64.png',
      sso: {
        url: REACT_APP_SSO_URL || DEFAULT_SSO,
        realm: 'ru'
      }
    },
    // {
    //     "key": "by",
    //     "name": "Belarus",
    //     "apiUrl": `https://api.by.${REACT_APP_API_BASE_URL || DEFAULT_API_BASE_URL}`,
    //     "logo64": "/img/flags/by-flag-64.png",
    //     "sso": {
    //         "url": REACT_APP_SSO_URL || "https://sso.playground.ecmr4.sis.lt",
    //         "realm": "by"
    //     }
    // },
    {
      key: 'lux',
      name: 'Benelux',
      apiUrl: `https://api.lux.${REACT_APP_API_BASE_URL || DEFAULT_API_BASE_URL}`,
      logo64: '/img/flags/lux-flag-64.png',
      sso: {
        url: REACT_APP_SSO_URL || DEFAULT_SSO,
        realm: 'lux'
      }
    },
    {
      key: 'observer',
      name: 'Observer',
      apiUrl: `https://api.observer.${REACT_APP_API_BASE_URL || DEFAULT_API_BASE_URL}`,
      logo64: '/img/flags/observer-flag-64.png',
      sso: {
        url: REACT_APP_SSO_URL || DEFAULT_SSO,
        realm: 'observer'
      }
    }
  ]
}
export default config
