import React from "react";
import './LoaderWithBackdrop.scss';
import { Spin } from "antd";

const LoaderWithBackdrop = ({ text }) => {

    return <div className="LoaderWithBackdrop">
        <Spin tip={text} size="large" />
    </div>
}

export default LoaderWithBackdrop;
