import React, { useState, useMemo } from "react";
import {
  Tag,
  Tooltip,
  Spin,
  message,
} from 'antd';
import {
  EyeInvisibleOutlined
} from "@ant-design/icons";
import './EditableTagGroup.scss';
import { deleteFileAccess } from '../../../services/cloudStorage';
import { getCurrentUserInfo } from '../../../services/indexRecord/indexRecord';
import { connect } from 'react-redux';
import AccessPanel from "./components/AccessPanel";
import ModalAddUser from "./components/ModalAddUser";
import { getAllScopesManagableAccess, getManagableAccess } from "../../utils/fileAccess";

const sortFirstOwner = (items) => {
  if (!items) return []
  return [
    items
      .find(({ policies }) => policies
        .flatMap(({ actions }) => actions)
        .indexOf("<.*>") > -1),
    ...items
      .filter(({ policies }) => policies
        .flatMap(({ actions }) => actions)
        .indexOf("<.*>") === -1)
  ]
}

const EditableTagGroup = (props) => {
  const [items, setItems] = useState(sortFirstOwner(props.items));
  const [loading, setLoading] = useState(false);
  const [isAddUserModalVisible, setIsAddUserModalVisible] = useState(false);

  const tags = useMemo(() => items.map((user) => user), [items])
  const accessManagable = useMemo(() => ({
    metadata: getManagableAccess(items, "metadata") || [],
    content: getManagableAccess(items, "content") || [],
    access: getManagableAccess(items, "access") || []
  }), [items])

  const currentUserAccess = useMemo(() => ({
    metadata: getAllScopesManagableAccess(items, "metadata") || [],
    content: getAllScopesManagableAccess(items, "content") || [],
    access: getAllScopesManagableAccess(items, "access") || []
  }), [items])

  const removeAccess = async (userId: string) => {
    try {
      const { uri } = props.document
      const [fileId] = uri
        .split('/')
        .slice(-1)
      const { country } = getCurrentUserInfo()
      await deleteFileAccess(fileId, userId, props.indexId, country)

      setItems(items
        .filter(({ userID }) => userID !== userId))
      props.document.access = props.document.access
        .filter(({ userID }) => userID !== userId)

      message.success({
        content: "User successfully removed",
        duration: 3,
      });
    } catch (err) {
      message.error({
        content: "Something went wrong",
        duration: 3,
      });
    }
  }

  const handleClose = async removedTag => {
    setLoading(true)
    await removeAccess(removedTag);
    setLoading(false)
    setItems(items.filter(({ userID }) => userID !== removedTag))
  };

  const openAddUserModal = () => {
    setIsAddUserModalVisible(true)
  }

  const { label } = props

  const dataAndActions = [
    {
      key: 'canReadAccess',
      accessType: 'access',
      action: 'get'
    },
    {
      key: 'canRemoveAccess',
      accessType: 'access',
      action: 'delete'
    },
  ]

  const [
    canReadAccess,
    canRemoveAccess
  ] = dataAndActions.map(item => currentUserAccess[item.accessType].accessActions &&
    (currentUserAccess[item.accessType].accessActions.indexOf(item.action) > -1 ||
      currentUserAccess[item.accessType].accessActions.indexOf('<.*>') > -1))

  return (
    <div className="EditableTagGroup">
      <ModalAddUser
        indexId={props.indexId}
        indexCountry={props.indexCountry}
        isAddUserModalVisible={isAddUserModalVisible}
        setIsAddUserModalVisible={setIsAddUserModalVisible}
        document={props.document}
        items={items}
        setItems={setItems} />
      <div className="label">{label}</div>
      {
        canReadAccess ?
          <div>
            <div>
              {tags.map((tag, index) => {
                const isLongTag = tag.length > 20;

                const tagElem = (
                  <Tag
                    className="edit-tag"
                    key={tag.userID}
                    closable={index !== 0 && canRemoveAccess}
                    onClose={() => handleClose(tag.userID)}
                  >
                    <span>
                      {isLongTag ? `${tag.slice(0, 20)}...` : tag.fullName}
                    </span>
                  </Tag>
                );
                return isLongTag ? (
                  <Tooltip title={tag} key={tag}>
                    {tagElem}
                  </Tooltip>
                ) : (
                  tagElem
                );
              })}
            </div>
            <AccessPanel
              indexId={props.indexId}
              indexCountry={props.indexCountry}
              items={items}
              document={props.document}
              removeAccess={removeAccess}
              openAddUserModal={openAddUserModal}
              currentUserAccess={currentUserAccess}
              accessManagable={accessManagable} />
          </div>
          :
          <div className="noAccess" >
            <EyeInvisibleOutlined /> {"You have no access to see this information"}
          </div>
      }
      {
        (loading || props.loading) &&
        <div className="accessSpinner">
          <Spin tip={"Updating access..."} size={"small"} />
        </div>
      }
    </div>
  );
}

export default connect()(EditableTagGroup);