export const getCurrentPosition = (callback, options) => {
  if (window.navigator.geolocation) {
    window.navigator.geolocation.getCurrentPosition(
      function (position) {
        callback({
          type: "success",
          data: {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          },
        });
      },
      function (err) {
        callback({
          type: "error",
          data: { message: err.message, code: err.code },
        });
      },
      options
    );
  }

  callback({
    type: "error",
    data: { message: "No Geolocation API" },
  });
};
