import React, { useEffect, useMemo, useState } from "react";
import {
    Spin,
    Descriptions,
    Collapse,
    Button,
    Modal,
    Tooltip
} from "antd";
import {
    EditOutlined,
    CloudDownloadOutlined,
    EyeInvisibleOutlined,
    FileOutlined,
    CheckCircleOutlined
} from "@ant-design/icons";
import './index.scss';
import { getDocument } from "../../../../../services/document";
import EditableTagGroup from "../../../EditableTagGroup";
import { getFileContentXslt, validateXml } from "../../../../../services/cloudStorage";
import ModalSelectSigners from "../../../ModalSelectSigners";
import config from "../../../../../config";
import { connect } from "react-redux";
import { getFileAccessAction, getFileMetadataAction } from "../../../../../store/modules/documentIndex/actions";
import { getAllScopesManagableAccess, parseFileIdFromUri } from "../../../../utils/fileAccess";
import { checkDocumentType } from "../../../../utils/document";

const { Panel } = Collapse;


const StorageCollapse = ({ index, getFileAccess, getFileMetadata, activeKeys, collapseKey }) => {
    const [documentMetaRetrived, setDocumentMetaRetrived] = useState(false);
    const [loadingDocumentMeta, setLoadingDocumentMeta] = useState(false);
    const [loadingDocument, setLoading] = useState(false);
    const [signDocumentModalVisislbe, setSignDocumentModalVisible] = useState(false)
    const [validatingDocument, setValidationLoading] = useState(false);
    const { document: { access, countryCode }, documentIdentifier } = index || { document: {} }

    const currentUserAccess = useMemo(() => ({
        metadata: getAllScopesManagableAccess(access, "metadata") || [],
        content: getAllScopesManagableAccess(access, "content") || [],
        access: getAllScopesManagableAccess(access, "access") || []
      }), [access])

    useEffect(() => {
        if (activeKeys.indexOf(collapseKey) > -1) {
            getDocumentMeta();
        }
    }, [])

    const dataAndActions = [
        {
          key: 'canGetDocument',
          accessType: 'content',
          action: 'get'
        },
      ]
    
      const [
        canGetDocument,
      ] = dataAndActions.map(item => currentUserAccess[item.accessType].accessActions &&
        (currentUserAccess[item.accessType].accessActions.indexOf(item.action) > -1 ||
          currentUserAccess[item.accessType].accessActions.indexOf('<.*>') > -1))

    // const canGetDocument = access && access
    //     .find(({ userID }) => getCurrentUserId() === userID)!.policies
    //     .find(({ resources }) => {
    //         const temp = resources.join(';');
    //         return temp.indexOf('content') > -1 || temp.indexOf('<.*>') > -1
    //     })!.actions
    //     .find(action => action === 'get' || action === '<.*>')

    const getCurrentDocumentXslt = async (uri) => {
        const [fileId] = uri
            .split('/')
            .slice(-1)
        setLoading(true);
        try {
            const { data } = await getFileContentXslt(fileId)
            let wnd = window.open("", "_blank");
            if (wnd) {
                wnd.document.write(data)
            }
        } catch (err) {
            Modal.warning({
                centered: true,
                title: "Document validation",
                content: <div>{err.response.data}</div>,
            });
        } finally {
            setLoading(false);
        }
    }

    const validateCurrentDocument = async (uri) => {
        const [fileId] = uri
            .split('/')
            .slice(-1)
        setValidationLoading(true);
        try {
            const { data } = await validateXml(fileId)
            if (data.valid) {
                Modal.success({
                    centered: true,
                    title: "Document validation",
                    content: <div>Document is valid</div>,
                });
            } else {
                Modal.warning({
                    centered: true,
                    title: "Document validation",
                    content: <div>{data.error}</div>,
                });
            }
        } catch (err) {
            Modal.error({
                centered: true,
                title: "Document validation",
                content: <div>{err.response.data}</div>,
            });
        } finally {
            setValidationLoading(false);
            setDocumentMetaRetrived(true)
        }
    }

    const getCurrentDocument = async (uri) => {
        try {
            setLoading(true);
            await getDocument(uri);
        } catch (err) {
            if (err.response && err.response.status == 404) {
                Modal.warning({
                    centered: true,
                    title: "Document retrival failed!",
                    content: <div>Document not found</div>,
                });
            } else if (err.response && err.response.status == 401) {
                Modal.error({
                    centered: true,
                    title: "Access denied",
                    content: <div>Do not have permission to access the file</div>,
                    onOk: () => {
                        window.location.reload();
                    },
                });

                return;
            } else {
                Modal.error({
                    centered: true,
                    title: "Document retrival failed!",
                    content: <div>{err.toString()}</div>,
                });
            }
            return;
        } finally {
            setLoading(false);
        }
    }

    const descriptionsStyle = { column: 1 };

    const getSingersText = (index) => {
        if (!index.document.notes) return

        const signingMeta = JSON.parse(index.document.notes)

        return Object.entries(signingMeta.signers)
            .map(([key, value]: any) => {
                const fullname = [value.name, value.surname].join(' ').trim()
                return <div>
                    <span>{fullname || key}</span>
                    <span className={`signStatus ${value.status}`}>{value.status}</span>
                </div>
            })
    }

    const getDocumentMeta = async () => {
        if (index.document.fileMetadata || documentMetaRetrived) return

        setLoadingDocumentMeta(true);
        try {
            const fileId = parseFileIdFromUri(index.document.uri)
            await Promise.all([
                getFileAccess(fileId),
                getFileMetadata(fileId)
            ])
        } catch (err) {
            console.log(err)
        } finally {
            setLoadingDocumentMeta(false)
            setDocumentMetaRetrived(true)
        }
    }

    // const requestAccess = (index) => {
    //   localStorage.setItem(
    //     index.document.documentIdentifier.value,
    //     JSON.stringify({ accessRequested: true })
    //   )
    // }

    return (
        <div className="StorageCollapse">
            {
                loadingDocument &&
                <div className="loadingDocumentSpinner" >
                    <Spin tip={"Getting document..."} size={"default"} />
                </div>
            }
            {
                validatingDocument &&
                <div className="loadingDocumentSpinner" >
                    <Spin tip={"Validating document..."} size={"default"} />
                </div>
            }
            <ModalSelectSigners
                indexId={index.documentIdentifier.value}
                fileId={parseFileIdFromUri(index.document.uri)}
                country={countryCode}
                visible={signDocumentModalVisislbe}
                setModalVisible={setSignDocumentModalVisible}
                fileMetadata={index.document.fileMetadata}
            />
            <Collapse
                onChange={getDocumentMeta}
                defaultActiveKey={activeKeys.map(key => `${key}_storageCollapse`)}
                style={{ width: "100%", marginTop: "1rem" }}>
                <Panel
                    header={<div className="storageCollapseHeader" >Storage</div>}
                    key={`${collapseKey}_storageCollapse`}
                    style={{ width: "100%" }}
                >
                    {
                        loadingDocumentMeta &&
                        <div className="storageSpinner" >
                            <div className="loadingDocumentSpinner" >
                                <Spin tip={"Retrieving document metadata..."} size={"default"} />
                            </div>
                        </div>
                    }
                    {
                        index.document.fileMetadata ?
                            <Descriptions {...descriptionsStyle} size="small" bordered>
                                <Descriptions.Item label="File name">
                                    {index.document.fileMetadata &&
                                        index.document.fileMetadata.name || '-'}
                                </Descriptions.Item>
                                <Descriptions.Item label="Format">
                                    {
                                        index.document.fileMetadata &&
                                        index.document.fileMetadata.format || '-'
                                    }
                                </Descriptions.Item>
                                {
                                    checkDocumentType(index.document.fileMetadata, 'xml') && 
                                    canGetDocument &&
                                    <Descriptions.Item className="actions" label="View the file">
                                        <Tooltip title="View the file">
                                            <Button
                                                type="dashed"
                                                onClick={() => getCurrentDocumentXslt(index.document.uri)}
                                                icon={<FileOutlined />} />
                                        </Tooltip>
                                    </Descriptions.Item>
                                }
                                {
                                    canGetDocument &&
                                    <Descriptions.Item className="actions" label="Download the file">
                                        <Tooltip title="Download the file">
                                            <Button
                                                onClick={() => getCurrentDocument(index.document.uri)}
                                                type="dashed"
                                                icon={<CloudDownloadOutlined />} />
                                        </Tooltip>
                                    </Descriptions.Item>
                                }
                                {
                                    checkDocumentType(index.document.fileMetadata, 'xml') && 
                                    canGetDocument &&
                                    <Descriptions.Item className="actions" label="Validate XML">
                                        {
                                            checkDocumentType(index.document.fileMetadata, 'xml') &&
                                            <Tooltip title="Validate XML">
                                                <Button
                                                    type="dashed"
                                                    onClick={() => validateCurrentDocument(index.document.uri)}
                                                    icon={<CheckCircleOutlined />} />
                                            </Tooltip>
                                        }
                                    </Descriptions.Item>
                                }
                                {
                                    config.useDokobit &&
                                    !index.document.notes &&
                                    canGetDocument &&
                                    checkDocumentType(index.document.fileMetadata, 'pdf') &&
                                    <Descriptions.Item className="actions" label="Request document signing">
                                        <Tooltip title="Request document signing">
                                            <Button
                                                type="dashed"
                                                onClick={() => setSignDocumentModalVisible(true)}
                                                icon={<EditOutlined />} />
                                        </Tooltip>
                                    </Descriptions.Item>
                                }
                                <Descriptions.Item label="Has access">
                                    <EditableTagGroup
                                        indexId={documentIdentifier ? documentIdentifier.value : null}
                                        indexCountry={countryCode}
                                        document={index.document}
                                        items={index.document.access} />
                                </Descriptions.Item>
                                {
                                    config.useDokobit &&
                                    index.document.notes &&
                                    <Descriptions.Item label="Signers">
                                        {getSingersText(index)}
                                    </Descriptions.Item>
                                }
                                <Descriptions.Item label="Notes">
                                    {
                                        index.document.fileMetadata.notes || '-'
                                    }
                                </Descriptions.Item>
                            </Descriptions> :
                            !loadingDocumentMeta &&
                            <div className="noAccess" >
                                <EyeInvisibleOutlined /> {"You have no access to see this information"}
                                {/* <Button
                        onClick={requestAccess}
                        className="requestAccess" >
                        Request access
                      </Button> */}
                            </div>
                    }
                </Panel>
            </Collapse>
        </div>
    );
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
    getFileAccess: getFileAccessAction(dispatch),
    getFileMetadata: getFileMetadataAction(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(StorageCollapse);
