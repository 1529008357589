import axios from "axios";
import fileSaver from "../../views/utils/fileSaver"

//  window.location.hostname === 'localhost' ? 'http://localhost:8080' :

export const documentsStorageOrigin = `https://${window.location.hostname}`

const getDocument = async (uri) => {
    const document = await axios.get(
        uri,
        {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access_token"),
            },
            // crossDomain: true,
        }
    );
    const { fileName, fileContent } = document.data;
    fileSaver(new Blob([new Uint8Array(fileContent.data)]), fileName);
    return document;
}

const addDocument = async (index) => {
    const { document, fingerprint } = index;

    if (!document || !document.content) return null;

    return await axios.post(
        `${documentsStorageOrigin}/api/v1/document`,
        {
            fileContent: document.content.data,
            fingerprint,
            fileName: document.content.name
        },
        {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access_token"),
            },
            // crossDomain: true,
        }
    );
}

const updateDocument = async (index) => {
    const { document, fingerprint } = index;

    if (!document || !document.content) return null;

    return await axios.post(
        `${documentsStorageOrigin}/api/v1/document`,
        {
            fileContent: document.content.data,
            fingerprint,
            fileName: document.content.name
        },
        {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access_token"),
            },
            // crossDomain: true,
        }
    );
}

export default {
    getDocument,
    addDocument,
    updateDocument,
}